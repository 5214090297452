/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "DM Sans";
}

.closeBtn {
  background-color: crimson;
  color: white;
  padding: 2px;
  top: 0;
  right: 0;
  position: absolute;
}

.account-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.account-active {
  background-color: rgb(2, 207, 2);
}

.account-inactive {
  background-color: rgb(255, 113, 61);
}

.driver-busy {
  background-color: dodgerblue;
}

.relative {
  position: relative;
}

.flex-gap {
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.count-up-values {
  color: white;
  font-weight: bold !important;
  font-size: 35px;
  line-height: 45px;
}

.timer-title {
  color: white;
  font-weight: bold !important;
  font-size: 17px;
}

.timer-amount {
  font-weight: 500 !important;
  font-size: 20px;
}

.force-wrap {
  white-space: wrap !important;
}

.back-btn:hover {
  background-color: #3e98d4;
  color: #fff;
}

.back-btn {
  background-color: #e6eaef;
  transition: 0.5s;
}

.view-btn {
  display: flex;
  background-color: #3e98d4;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 6px 8px 6px 8px;
  font-size: 15px;
  column-gap: 2px;
  margin-left: 15px;
}

.view-btn:hover {
  background-color: #007fd4;
}

.animated-bar {
  transition: 0.4s;
}

.homepage {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../assets/images/homepage.jpg) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
}

.appfooter {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .appfooter {
    display: block !important;
  }
  .homepagecontent {
    padding-left: 30px !important;
    padding-right: 30px !important;
    display: block !important;
  }
  .homeheader {
    font-size: 40px !important;
  }
  .homedesc {
    font-size: 18px !important;
    margin-bottom: 80px !important;
  }
  .part1 {
    width: 100% !important;
    min-height: calc(100vh - 65px) !important;
  }

  .part2 {
    width: 100% !important;
    justify-content: start !important;
  }

  .image-app {
    height: 100% !important;
    width: 100% !important;
    position: relative;
    /* top: -10px; */
  }

  .appnavbar {
    padding-left: 20px !important;
    padding-right: 30px !important;
  }
  .privacylink,
  .helplink,
  .copyright {
    display: none !important;
  }
  .homepage {
    height: 220vh !important;
    position: relative;
  }
  .tospagecontainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .links-cont {
    column-gap: 5px !important;
  }
  .imgcont {
    width: 160px !important;
  }
  @media screen and (max-width: 450px) {
    .imgcont {
      position: relative;
      left: -2px !important;
    }
    .langCont {
      margin-left: 0px !important;
      position: relative;
      left: 10px !important;
    }
    .langCont > img {
      object-fit: contain !important;
    }
  }
}

.selectBtn {
  border: 1px solid green;
  color: green !important;
}

.xclosebtn {
  background-color: crimson;
  position: absolute;
  right: 0;
  top: -5px;
  border-radius: 5px;
}

.xclosebtn:hover {
  background-color: rgb(245, 3, 51);
}

.image-app {
  transform: scale(1.1) !important;
}

.toslist li::marker {
  font-weight: bold;
  font-size: 24px;
}

.tuslist li::marker {
  font-weight: bold;
  font-size: 18px !important;
}

.last-item {
  list-style-type: none !important;
}

.contact-us-page {
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
}

@media screen and (max-width: 650px) {
  .contact-us-page {
    display: block !important;
  }
}
