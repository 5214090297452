:root {
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #3e98d4;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #505f73;
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
  --dark-text-color: #030b16;
  --success-text: #16db85;
  --danger-color: #f43a4d;
}

.text-dark {
  color: var(--dark-text-color) !important;
}

.dashboard-container {
  padding: 24px;
}

.customers-table {
  width: 100%;
}

.text-blue {
  color: var(--primary-color) !important;
}

.table-nav,
.chart-nav {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.chart-nav {
  /* margin-bottom: 20px; */
}

.tb-title {
  font-weight: 600;
  color: var(--dark-text-color);
  font-size: 20px;
}

.customers-table-container {
  border: 1px solid #e6eaef;
  border-radius: 16px;
  background-color: white;
  width: 100%;
}

.customers-table,
.customers-table thead tr th,
.customers-table tbody tr:not(:last-of-type) td {
  border-bottom: 1px solid #e6eaef;
}

td {
  white-space: nowrap !important;
}

.pricing-table,
.pricing-table thead tr th {
  border-bottom: 1px solid transparent !important;
}

.pricing-table,
.pricing-table tbody td {
  border-bottom: 1px solid #e6eaef !important;
}

.pricing-table tbody tr:last-child td {
  border-bottom: 1px solid transparent !important;
}

.customers-table,
.customers-table thead tr th,
.customers-table tbody tr td {
  padding: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 13px;
}

.drivers-table,
.drivers-table thead tr th,
.drivers-table tbody tr td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.customers-table,
.customers-table thead tr th {
  text-align: left;
}

.customers-table tbody tr td:first-child,
.customers-table thead tr th:first-child {
  padding-left: 40px;
}

tbody tr:last-child td {
  border-bottom: 1px solid #f2f4f6 !important;
}

.customers-table thead tr th {
  background-color: #f2f4f6;
  font-weight: 500;
  color: var(--text-color);
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pricing-table thead tr th {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.customers-table thead tr th:first-of-type {
  border-top-left-radius: 16px;
}
.customers-table thead tr th:last-of-type {
  border-top-right-radius: 16px;
}

.customers-table-container {
  overflow-x: auto !important;
  max-width: 100%;
}

.pricing-table thead tr th:first-of-type {
  border-top-left-radius: 0px !important;
}
.pricing-table thead tr th:last-of-type {
  border-top-right-radius: 0px !important;
}

.customers-table tbody tr td {
  color: var(--dark-text-color);
  font-weight: 500;
}

.customers-table tbody tr:hover {
  background-color: #fbfbfb;
}

.customers-table tbody .search_tr:hover {
  background-color: white !important;
}

.custom_dropdown_button {
  border: 1px solid transparent !important;
  padding: 0px;
  font-size: 16px;
  color: var(--text-color);
  background-color: transparent !important;
}

.custom_dropdown_button:focus {
  background-color: transparent !important;
}

.menu-list {
  /* border-radius: 10px !important; */
  padding: 9px !important;
  padding-left: 10px !important;
  padding-right: 6px !important;
  margin-bottom: 5px;
}

.menu-list:hover {
  background-color: #f2f4f6 !important;
}

.menuListContainer {
  padding: 10px;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden !important;
  max-height: 1500px !important;
}

.date-range-picker-container {
  padding: 0px !important;
  border: 1px solid rgb(239, 239, 239);
  overflow: hidden !important;
  padding-bottom: 6px !important;
  max-height: 1500px !important;
  border: 1px solid rgb(232, 231, 231) !important;
}

.radioChecker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e6eaef;
  margin-right: 10px;
}

.activeMenuList {
  background-color: #f2f4f6 !important;
  border-radius: 8px;
}

.active-dot {
  width: 12px;
  height: 12px;
  background-color: #e6eaef !important;
  border-radius: 50%;
}

.active-dropdown {
  background-color: var(--primary-color) !important;
}

.search-icon {
  position: relative;
  left: -28px;
  z-index: 1;
}

.search-field {
  padding-right: 35px !important;
  padding-left: 20px !important;
  font-size: 15px;
  border: none !important;
}

.search-field:focus,
.form-inputs:focus,
.phone-number-input:focus {
  border: 1px solid #3e98d4 !important;
  box-shadow: none !important;
  outline: none;
}

.filterTimeBtn {
  background-color: white !important;
  padding-right: 10px !important;
  border: 1px solid rgb(190, 189, 189);
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
}

.filterTimeBtn1 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: 1px solid rgb(232, 232, 232) !important;
  border-right: none !important;
}
.filterTimeBtn:focus {
  background-color: white !important;
}

.filterTimeBtn2 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.filterTimeBtn3 {
  border: 1px solid rgb(232, 232, 232) !important;
}

.filterTimeBtn:hover {
  background-color: white !important;
}

.filter-time {
  font-weight: 500;
  font-size: 14px;
  color: #131313;
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
}

@media screen and (min-width: 1200px) {
  .search-field {
    min-width: 400px !important;
  }
}

.rotate {
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.rotate.down {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
  top: 0px;
}

.accord-icon {
  position: relative;
  top: 3px;
  left: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.success-text {
  color: var(--success-text);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 5px;
  left: 10px;
}

.arrow-up-icon {
  position: relative;
  top: 2px;
  left: 3px;
}

.menu-list-sm {
  font-size: 14px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  margin-bottom: 4px;
}

.mr-4 {
  margin-right: 30px;
}

.selected-option {
  color: var(--text-color);
  margin-bottom: 1px;
  font-weight: 500;
}

.question-icon {
  position: relative;
  top: 4px;
  left: 6px;
}

.no-botom {
  position: relative;
  top: 5px;
}

.chart-label {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 50px;
  position: relative;
  padding-top: 10px;
}

.chart-label > h2 {
  color: var(--text-color);
  position: relative;
  top: 10px;
}

.primary-line {
  width: 30px;
  height: 2px;
  background-color: var(--primary-color);
  margin-right: 15px;
}
.input-labels {
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 3px !important;
}

.modal-header {
  border-bottom: 1px solid #e6eaef;
  margin-bottom: 10px;
}

.saveBtn {
  background-color: var(--primary-color) !important;
  color: white;
}

.btn-radius {
  border-radius: 11px !important;
}

.modal-pad {
  margin-bottom: 10px;
}

.cancelBtn {
  background-color: white !important;
  border: 1px solid #e6eaef;
}

.text-sm {
  font-size: 14px !important;
}

.cancel-btn {
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 7px !important;
}

.cancel-btn:hover {
  border: 1px solid #3e98d4;
}

.phone-number-container {
  width: 100% !important;
  border: 1px solid #e6eaef;
  border-radius: 10px;
}
.currency-input-field {
  width: 100% !important;
  border: 1px solid #e1e4e8;
  border-radius: 10px;
  padding: 9px 15px 9px 15px;
  outline-color: #3e98d4;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.phone-number-input {
  width: 100% !important;
  border: none !important;
  border-right: 1px solid #e6eaef !important;
}

.buttonClass {
  border: 1px solid #e6eaef !important;
}

.inpt-cont {
  margin-bottom: 12px;
}

.inpt-cont {
  width: 100%;
  gap: 20px;
}

.start-btn {
  text-align: left;
  float: left;
}

.modal-footer {
  display: flex;
  justify-content: space-between !important;
}

.dropdown-input-label {
  font-weight: normal !important;
}

.select-dropdown-input {
  display: flex;
  align-items: center !important;
  padding-top: 13px !important;
  width: 100% !important;
  padding-bottom: 13px !important;
}

.radioChecker-dropdown {
  position: relative;
  top: 5px;
}

.input-field-cont {
  width: 100%;
  overflow: hidden !important;
}

.custom_dropdown_button_full {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.input-field-cont-dropdowns2 {
  margin-bottom: 0px;
}
.logo-app {
  width: 65px;
  height: 65px;
  position: relative;
  top: 10px;
}

.logo-app > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.app-icon-label {
  position: relative;
  top: 25px;
}

.form-fields-error-message {
  font-size: 14px;
  color: var(--danger-color);
}

.justify-center {
  justify-content: center !important;
}

.items-center {
  align-items: center !important;
}

.red-border {
  border: 1px solid var(--danger-color) !important;
}

.disabled-btn {
  background-color: #e6eaef !important;
}

.text-center {
  text-align: center !important;
}
.pagButton > a {
  padding: 5px 14px !important;
}

.pagButton-border {
  border: 1px solid rgb(215, 215, 215);
}

.nextBtn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.prevBtn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pag-container {
  margin: auto !important;
}

.showing-page {
  padding-left: 40px;
  font-size: 15px;
}

.pagContainer {
  align-items: center;
  /* justify-content: center; */
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 100px;
}

.pagButton:hover {
  background-color: var(--primary-color-light);
}

.pagButton-border-active {
  background-color: #3e98d4;
  color: white !important;
  border: 1px solid #3e98d4 !important;
}

.pagButton-border-active:hover {
  background-color: #3e98d4 !important;
}

.pag-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pg-tab {
  width: 100% !important;
  text-align: center;
}

.pag-buttons {
  min-width: 60% !important;
}

.rdrStartEdge,
.rdrEndEdge {
  background-color: var(--primary-color) !important;
}

.rdrYearPicker > select {
  font-size: 14px !important;
  font-family: arial !important;
  font-style: normal !important;
  background-color: white !important;
}

.rdrMonthPicker > select {
  font-size: 14px !important;
  font-family: arial !important;
  background-color: white !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.search-input_nopad {
  position: relative;
  left: 20px !important;
}

.menuListContainer_nopad > .menu-list-edit:hover {
  color: var(--primary-color) !important;
}

.menuListContainer_nopad > .menu-list {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-bottom: 2px !important;
  font-size: 15px !important;
}

.font-semibold {
  font-weight: 550;
}

.product-desc {
  gap: 7px !important;
}

.text-active {
  color: rgb(10, 186, 10) !important;
  font-weight: 550;
}

.border-left {
  background-color: #bcc5d1;
  height: 17px;
  position: relative;
  top: 3.5px;
  border-left: 2px solid #bcc5d1 !important;
}

.cancelTimes:hover {
  color: #f52323 !important;
}

.refundBtn {
  color: #788698;
}

.refundBtn:hover {
  color: #ffc939;
}

.product-footer {
  column-gap: 7px !important;
  flex-wrap: wrap !important;
}

.border-gray {
  background-color: var(--text-color);
}

.text-gray {
  color: var(--text-color) !important;
}

.product_cont {
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #e2e1e1 !important;
  border-radius: 7px;
}

.product_cont:hover {
  border: 1px solid #3e98d4 !important;
}

.modal-body-cont {
  overflow-y: auto !important;
  max-height: 80vh !important;
}

@media screen and (max-width: 1600px) {
  .modal-body-cont {
    max-height: 76vh !important;
  }
}

@media screen and (max-width: 1450px) {
  .modal-body-cont {
    max-height: 70vh !important;
  }
}

.modal-pad2 {
  padding: 5px !important;
}

.font-semibold-sm {
  font-weight: 500 !important;
}

.product-desc {
  width: 100%;
}

.product-footer {
  width: 100%;
}

.product_sub_cont {
  width: 100%;
}

.modal-sub-header {
  font-size: 16px !important;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 7px;
}

.refund_cont {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.confirm-btns {
  padding: 2px 20px 2px 20px !important;
}

.bg-danger {
  background-color: var(--danger-color) !important;
}

.bg-danger:hover {
  background-color: #ec0f25 !important;
}

.text-white {
  color: white !important;
}

.py-20 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.refunded {
  color: #16db85 !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.items-end {
  align-items: flex-end;
}

.w-full {
  width: 100% !important;
}

.text-right {
  text-align: right !important;
}

.main-btn {
  background-color: #3e98d4 !important;
  color: white !important;
}

.centered-component {
  padding: 60px 0px 75px 0px !important;
  justify-content: center;
  text-align: center;
}

.text-base {
  font-size: 15px;
}

select-input-default {
  border: 1px solid rgb(232, 232, 232) !important;
}

.refunded-disabled {
  color: transparent !important;
}

.delete-menu-item:hover {
  color: #f43a4d !important;
}

.search-cont-panel {
  display: flex !important;
}

@media screen and (max-width: 650px) {
  .customers-table-container {
    overflow-x: auto !important;
    max-width: 100%;
  }
  .table-nav {
    display: block !important;
  }
  .search-cont-panel {
    display: block !important;
  }

  .wrap {
    display: block !important;
  }
}

/* Standard */
input::placeholder {
  text-transform: capitalize !important;
}

/* For older versions of WebKit browsers */
input::-webkit-input-placeholder {
  text-transform: capitalize !important;
}

/* For Firefox */
input::-moz-placeholder {
  text-transform: capitalize !important;
}

/* For Internet Explorer 10+ */
input:-ms-input-placeholder {
  text-transform: capitalize !important;
}

/* For Edge */
input::-ms-input-placeholder {
  text-transform: capitalize !important;
}
